// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'user',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'name',
    Object: 'value',
    label: '',
    width: '120'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'address',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'postal_code',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'unit_no',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'building_name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'current',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'updated_at',
    label: '',
    Object: 'value',
    width: '160'
  }]
}
